// import '../css/style.scss'
import { ua } from './modules/ua'
import lottie from 'lottie-web'
// import inView from 'in-view'
// import Swiper from 'swiper/bundle'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    this.initPages()
  }

  initPages() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)

    window.addEventListener('load', () => {
      setTimeout(() => {
        this.body.classList.add(`is-loaded`)
      }, 1000)
    })

    // ナビゲーション開閉
    document.querySelector('.nav-trigger').addEventListener('click', () => {
      document.querySelector('body').classList.toggle(klass.nav)
    })

    // ナビゲーションを押下時に閉じる
    Array.from(document.querySelectorAll('.header-nav a')).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        document.querySelector('body').classList.toggle(klass.nav)
      })
    })

    // inView('.inview').on('enter', (el) => {
    //   el.classList.add('is-view')
    // })
    // inView.offset(100)

    if (this.body.classList.contains('page-index')) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 300) {
          this.body.classList.add('is-scrolled')
        } else {
          this.body.classList.remove('is-scrolled')
        }
      })
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.sc22 = new App()
})
